/* eslint-disable */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';

import StandardOutboundLink from '../../components/standard-outbound-link';
import SEO from '../../components/seo';

const Privacy = () => (
  <Layout>
    <SEO
      title="Privacy | Privacy Policy for Code Championship"
      description="Code Championship understands how important privacy is to our community, especially kids and parents. Learn about our policy here."
      keywords={['privacy', 'privacy policy']}
    />
    <h1>Code Championship Privacy Policy</h1>
    <div className="stripe-hr thin" />
    <div class="inner info-inner">
      <section>
        <p>
          <i>The Code Championship Privacy Policy was last updated: July 28, 2020</i>
        </p>
        <p class="intro">Code Championship understands how important privacy is to our community, especially kids and parents. We wrote this Privacy Policy to explain what information we collect through our website (codechampionship.com) (the "Site"), how we use, process, and share it, and what we're doing to keep it safe. It also tells you about your rights and choices with respect to your information, and how you can contact us if you have any questions or concerns.</p>
      </section>
      <section id="collection">
        <dl>
          <span class="nav-spacer">
          </span>
          <h3>What personal information does Abamath LLC collect about me?</h3>
          <p>For the purpose of this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable individual. We obtain Personal Information relating to you from various sources described below.</p>
          <p>Where applicable, we indicate whether and why you must provide us with your Personal Information, as well as the consequences of failing to do so. If you do not provide Personal Information when requested, you may not be able to benefit from our Site if that information is necessary to provide you with the service or if we are legally required to collect the information.</p>
          <dt>Payment/Credit Card Information</dt>
          <dd>In order to make a payment (for tournament registration or tournament creation), we will ask you to provide credit card information. We rely on <StandardOutboundLink href="https://stripe.com/">Stripe</StandardOutboundLink> for payment processing. We send information like your credit card number directly to them, it is not transmitted to our servers, and we do not store it in our database.</dd>
          <dt>Event Registration Information</dt>
          <dd>In order to register for a Code Championship tournament, we ask you for your first name, last name, email address (or your parent or guardian's email address if you are under 18 years old), and possibly additional information relevant to a specific event (like gender or grade in school). For paid events, we also ask for credit card information. Credit card information is routed through that you select a username that does not disclose your real name or other information that could identify you. Other users can see your username and country, but not your age, gender, or email address.</dd>
          <dt>Tournament Creation Information</dt>
          <dd>In order to create a Code Championship tournament, we ask you for your email address.</dd>
          <dt>Video, Audio, and Photographic Information</dt>
          <dd>
            During tournaments, some Personal Information, specifically first name and last initial will be shared with other attendees. For virtual events:
            <ul>
              <li>Participants who share their video will be visible to organizers and other participants.</li>
              <li>Participants who use their microphone will be audible to organizers and other participants.</li>
              <li>Participants who share their screen will allow organizers and other participants to view their screen.</li>
            </ul>
          </dd>
          <dt>User-generated Content</dt>
          <dd>We collect any information that you provide to us when you create Code Championship project (including unshared projects). We may share these projects along with first name and last initial for marketing and promotional purposes.</dd>
          <dt>Communications</dt>
          <dd>If you contact us directly, we may receive additional information about you. For example, when you contact our Customer Support Team, we may receive your name, email address, phone number, the contents of a message or attachments that you may send to us, and other information you choose to provide.</dd>
          <dt>Personal Information We Collect Automatically From Your Use of the Site</dt>
          <dd>When you use Code Championship, we and our third-party service providers collect information about you and your device through automated means, such as cookies and web server logs. By using Code Championship, you consent to the placement of cookies and similar technologies in your browser in accordance with this Privacy Policy. The information collected in this manner includes your IP address, network location, what browser you are using, device IDs and characteristics, operating system version, language preferences, referring URLs, and information about the usage of our site.</dd>
          <dd>We use this information, for example, to ensure that the site functions properly, to determine how many users have visited certain pages, or to prevent fraud. We use IP address information to derive your approximate location.  We also work with analytics providers, such as Google Analytics, which use cookies and similar technologies to collect and analyze information about use of the site and report on activities and trends. These services may also collect information about the use of other websites, apps, and online resources. You can learn more about Google’s practices by going to <StandardOutboundLink href="https://www.google.com/policies/privacy/partners/" />.</dd>
          <dd>If you do not want information collected through the use of cookies, most browsers allow you to automatically decline cookies or be given the choice of declining or accepting the transfer to your computer of a particular cookie (or cookies) from a particular site. You may also wish to refer to <StandardOutboundLink href="http://www.allaboutcookies.org/manage-cookies/index.html" />. If, however, you do not accept cookies, you may experience some inconvenience in your use of Code Championship.</dd>
        </dl>
      </section>
      <section id="usage">
        <dl>
          <span class="nav-spacer">
          </span>
          <h3>How does Abamath LLC use my personal information?</h3>
          <p>
          </p>
          <dt>Internal and Service-Related Usage</dt>
          <dd>We use Personal Information for internal and Site-related purposes, including to operate, provide, and maintain the Site and events.</dd>
          <dt>Analytics and Improving the Site</dt>
          <dd>We and our service providers use Personal Information that we collect on the Site, such as your location and your activities on the Site, to monitor and analyze usage of the Site and to improve and enhance the Site.</dd>
          <dt>Communications</dt>
          <dd>We may send emails to an email address you provide to us for customer-service or technical-support purposes, to send you information about tournaments for which you have registered, or updates about the latest developments or features on the Site. We may also send email to the email address you provide to us if you subscribe to our mailing list.</dd>
          <dt>Legal</dt>
          <dd>We may use your Personal Information to enforce our <Link to="/terms/">Terms of Use</Link>, to defend our legal rights, and to comply with our legal obligations and internal policies. We moderate all content posted to Code Championship, including unshared projects and tournaments.</dd>
          <dd>If you are located in the European Economic Area, we only process your Personal Information based on a valid legal ground, including when:</dd>
          <ul>
            <li>You have consented to the use of your Personal Information, for example, to receive electronic marketing communications;</li>
            <li>We need your Personal Information to provide our services, including for tournament registration, to respond to your inquiries, or for customer support;</li>
            <li>We have a legal obligation to use your Personal Information; or</li>
            <li>We or a third party have a legitimate interest in using your Personal Information. In particular, we have a legitimate interest in using your Personal Information to personalize our services and provide you with tailored content, conduct business analytics, and otherwise improve the safety, security, and performance of our Site. We only rely on our or a third party’s legitimate interests to process your Personal Information when these interests are not overridden by your rights and interests.</li>
          </ul>
        </dl>
      </section>
      <section id="share">
        <span class="nav-spacer">
        </span>
        <h3>How Does Code Championship Share my Personal Information?</h3>
        <p>We disclose information that we collect through the Site for the following uses:</p>
        <ul>
          <li>We may disclose your information to third-party service providers who provide services such as website hosting, data analysis, information technology and related infrastructure provisions, customer service, email delivery, and other services.</li>
          <li>We may disclose your information to organizations who partner with us to host events.</li>
          <li>To a potential or actual acquirer, successor, or assignee as part of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our organization or assets. You will have the opportunity to opt out of any such transfer if the new entity’s planned processing of your information differs materially from that set forth in this Privacy Policy.</li>
          <li>If required to do so by law or in the good faith belief that such action is appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, such as school, school districts, and law enforcement, including public and government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
        </ul>
      </section>
      <section id="thirdparties">
        <span class="nav-spacer">
        </span>
        <h3>Third Party Services</h3>
        <p>This Privacy Policy applies only to the processing of your Personal Information by Code Championship. It does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or service to which the Site links. The inclusion of a link on the Site does not imply endorsement of the linked site or service by us or by our affiliates.</p>
      </section>
      <section id="rights">
        <span class="nav-spacer">
        </span>
        <h3>Your Rights and Choices</h3>
        <p>
        </p>
        <dl>
          <dt>Marketing Communications</dt>
          <dd>You may also opt out from receiving email from us by sending your request to us by email at help@codechampionship.com. Please be aware that, even after you opt out from receiving marketing communications from us, you may continue to receive administrative messages from us regarding purchases.</dd>
          <dt>Your Data Protection Rights (EEA)</dt>
          <dd>In certain jurisdictions, you have the right to request access and receive information about the Personal Information we maintain about you, to update and correct inaccuracies in your Personal Information, to restrict or object to the processing of your Personal Information, to have the information blocked, anonymized, or deleted, as appropriate, or to exercise your right to data portability to easily transfer your Personal Information to another company. Those rights may be limited in some circumstances by local law requirements. In addition to the above-mentioned rights, you also have the right to lodge a complaint with a competent supervisory authority subject to applicable law.</dd>
          <dd>Where required by law, we obtain your consent for the processing of certain Personal Information collected by cookies or similar technologies, or used to send you direct marketing communications, or when we carry out other processing activities for which consent may be required. If we rely on consent for the processing of your Personal Information, you have the right to withdraw it at any time and free of charge. When you do so, this will not affect the lawfulness of the processing before your consent withdrawal.</dd>
          <dd>To update your preferences, ask us to remove your information from our mailing lists, or submit a request to exercise your rights under applicable law, please email us at help@codechampionship.com.</dd>
        </dl>
      </section>
      <section id="retention">
        <span class="nav-spacer">
        </span>
        <h3>Data Retention</h3>
        <p>We take measures to delete your Personal Information or keep it in a form that does not allow you to be identified when this information is no longer necessary for the purposes for which we process it, unless we are required by law to keep this information for a longer period. When determining the retention period, we take into account various criteria, such as the type of services requested by or provided to you, the nature and length of our relationship with you, possible re-enrolment with our services, the impact on the services we provide to you if we delete some information from or about you, mandatory retention periods provided by law and the statute of limitations.</p>
      </section>
      <section id="protection">
        <span class="nav-spacer">
        </span>
        <h3>How does Abamath LLC protect my personal information?</h3>
        <p>Abamath LLC has in place administrative, physical, and technical procedures that are intended to protect the information we collect on the Code Championship website against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Information in our possession. For example, we use SSL/TLS for all data transfer. However, as effective as these measures are, no security system is impenetrable. We cannot completely guarantee the security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the Internet.</p>
      </section>
      <section id="changes">
        <span class="nav-spacer">
        </span>
        <h3>Notifications of changes to the Privacy Policy</h3>
        <p>We review our security measures and Privacy Policy on a periodic basis, and we may modify our policies as appropriate. If we make material changes, we will notify you through the Site or by sending you an email or other communication. We encourage you to review our Privacy Policy on a regular basis. The “Last Updated” date at the top of this page indicates when this Privacy Policy was last revised. Your continued use of the Site following these changes means that you accept the revised Privacy Policy.</p>
      </section>
      <section id="transfer">
        <span class="nav-spacer">
        </span>
        <h3>International Cross-Border Data Transfer</h3>
        <p>Code Championship is based in the United States. Personal Information that we collect may be transferred to, and stored at, any of our affiliates, partners, or service providers which may be inside or outside the European Economic Area, including the United States. By submitting your personal data, you agree to such transfers.</p>
      </section>
      <section id="help">
        <span class="nav-spacer">
        </span>
        <h3>What can I do to help protect privacy on Code Championship?</h3>
        <p>Please do not share personal contact information (such as your name, physical address, email address, or phone number) in projects. Please let us know if you see this kind of information by sending an email to security@codechampionship.com. It is also important that you maintain the security and control of your project and tournament credentials, and not share your project or tournament secret keys with anyone.</p>
      </section>
    </div>
  </Layout>
);

export default Privacy;
